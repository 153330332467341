<template>
  <!--    Aggregate Form-->
  <validation-observer
    ref="aggregateForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateAggregateMode?'Update':'Create'} Aggregate Setting`"
      :show="aggregateModalOpened"
      :show-overlay="deletingAggregate"
      size="md"
      @onClose="closeAggregateModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedAggregateId"
            placeholder="All Aggregate Settings"
            :options="aggregates"
            label-name="title"
            value-name="id"
            @input="populateAggregateForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteAggregate()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetAggregateForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="aggregate.title"
        name="title"
        placeholder="Title, eg. Upper Primary Aggregate Setting"
        rules="required|min_length:3"
        label="Title"
      />
      <div class="mt-2">
        <lenon-multi-select
          v-model="aggregate.core_subjects"
          name="core_subjects"
          placeholder="Select Subjects"
          label-name="name"
          value-name="id"
          :options="coreSubjects"
          :rules="`${+aggregate.best_core_count<1?'required':''}`"
          label="Core Subjects"
        />
        <div>
          <small><b>Selected core subjects will be used to grade students regardless of the grade they get.</b></small>
        </div>
      </div>
      <div class="mt-2">
        <lenon-input
          v-model="aggregate.best_core_count"
          name="number_of_best_core_subjects"
          placeholder="# of best core subjects to use"
          type="number"
          label="Number of Best Core Subjects"
          :rules="`max_value:${maxCoreSubjects}`"
        />
        <div style="margin-top: -15px !important;">
          <small><b>Lenon will compare remaining core subjects (the subjects you did not select) and pick the best {{ aggregate.best_core_count||'number you enter' }}
            and combine {{ +aggregate.best_core_count===1?'it':'them' }} with selected subjects for grading. In all {{ totalCores }} core subjects will be used to grade students.
          </b></small>

        </div>
      </div>
      <div class="mt-2">
        <lenon-multi-select
          v-model="aggregate.elective_subjects"
          name="elective_subjects"
          placeholder="Select Subjects"
          label-name="name"
          value-name="id"
          :options="electiveSubjects"
          :rules="`${+aggregate.best_elective_count<1?'required':''}`"
          label="Elective Subjects"
        />
        <div>
          <small><b>Selected elective subjects will be used to grade students regardless of the grade they get.</b></small>
        </div>
      </div>
      <div class="mt-2">
        <lenon-input
          v-model="aggregate.best_elective_count"
          name="number_of_best_elective_subjects"
          placeholder="# of best elective subjects to use"
          type="number"
          label="Number of Best Elective Subjects"
          :rules="`max_value:${maxElectiveSubjects}`"
        />
        <div style="margin-top: -15px !important;">
          <small><b>Lenon will compare remaining elective subjects (the subjects you did not select) and pick the best {{ aggregate.best_elective_count||'number you enter' }}
            and combine {{ +aggregate.best_elective_count===1?'it':'them' }} with selected subjects for grading. In all {{ totalElectives }} elective subjects will be used to grade students.
          </b></small>

        </div>
      </div>
      <div class="mt-2">
        <lenon-multi-select
          v-model="aggregate.exclude_subjects"
          name="exclude_subjects"
          label-name="name"
          value-name="id"
          placeholder="Select Subjects"
          :options="filteredSubjects"
          label="Excluded Subjects"
        />
        <div>
          <small><b>Selected subjects will not be used to grade students.</b></small>
        </div>
      </div>
      <div class="mt-2">
        <lenon-multi-select
          v-model="aggregate.classes"
          name="classes"
          label-name="name"
          value-name="id"
          placeholder="Select Classes"
          :options="filteredClasses"
          rules="required"
          label="Affected Classes"
          @remove="handleRemoveClass"
        />
        <div v-html="missingClassText('settings')" />
      </div>
      <b-form-checkbox
        v-model="allClasses"
        class="mt-1"
      >
        Select All Classes
      </b-form-checkbox>
      <div
        v-if="!invalid"
        class="mt-1"
      >
        <small><b>Students will be graded with {{ totalElectives+totalCores }} subjects, {{ totalCores }} core subjects and {{ totalElectives }} electives.</b></small>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeAggregateModal()"
          />
          <lenon-button
            :label="updateAggregateMode?'Update':'Create'"
            :disabled="invalid"
            :loading="aggregateLoading"
            loading-text="Loading..."
            @onClick="updateAggregateMode?updateAggregate():createAggregate()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { missingClassText } from '@/libs/dnd'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import { CREATE_AGGREGATE_M, DELETE_AGGREGATE_M, UPDATE_AGGREGATE_M } from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'

export default {
  name: 'AggregateSetting',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      removedClassIds: [],
      missingClassText,
      allClasses: false,
      aggregateModalOpened: false,
      updateAggregateMode: false,
      aggregateLoading: false,
      deletingAggregate: false,
      selectedAggregateId: null,
      aggregate: {
        id: null,
        title: null,
        core_subjects: [],
        best_core_count: null,
        elective_subjects: [],
        best_elective_count: null,
        exclude_subjects: [],
        classes: [],
      },
    }
  },
  computed: {
    totalElectives() {
      return +this.aggregate.best_elective_count + (this.aggregate.elective_subjects.length)
    },
    totalCores() {
      return +this.aggregate.best_core_count + (this.aggregate.core_subjects.length)
    },
    maxCoreSubjects() {
      return (this.coreSubjects.length - this.aggregate.core_subjects.length)
    },
    maxElectiveSubjects() {
      return (this.electiveSubjects.length - this.aggregate.elective_subjects.length)
    },
    selectedAggregate() {
      return this.aggregates.find(ter => ter.id === this.selectedAggregateId)
    },
    aggregates() {
      return this.$store.getters['studentGrading/aggregates']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    coreSubjects() {
      const coreIds = this.aggregate.exclude_subjects.filter(s => s.tag === 1).map((sb => sb.id))
      return this.subjects.filter(s => s.tag === 1).filter((s => !coreIds.includes(s.id)))
    },
    electiveSubjects() {
      const elIds = this.aggregate.exclude_subjects.filter(s => s.tag === 0).map((sb => sb.id))
      return this.subjects.filter(s => s.tag === 0 && !elIds.includes(s.id))
    },
    filteredSubjects() {
      const allSelectedSubjects = [
        ...this.aggregate.core_subjects.map(s => s.id),
        ...this.aggregate.elective_subjects.map(s => s.id),
      ]
      return this.subjects.filter(s => !allSelectedSubjects.includes(s.id))
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.aggregates.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.aggregate.classes = this.filteredClasses
      } else {
        this.aggregate.classes = []
      }
    },
    modalOpened(opened) {
      this.aggregateModalOpened = opened
    },
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    // aggregate
    resetAggregateForm() {
      this.aggregate = {
        id: null,
        title: null,
        core_subjects: [],
        best_core_count: null,
        elective_subjects: [],
        best_elective_count: null,
        exclude_subjects: [],
        classes: [],
      }
      this.updateAggregateMode = false
      this.allClasses = false
      this.selectedAggregateId = null
      this.$refs.aggregateForm.reset()
    },
    populateAggregateForm(agg) {
      if (this.selectedAggregate) {
        this.updateAggregateMode = true
        this.removedClassIds = []
        this.aggregate = {
          ...this
            .selectedAggregate,
          elective_subjects: this.selectedAggregate.elective_subjects.map(s => this.subjects.find(e => +e.id === +s)),
          core_subjects: this.selectedAggregate.core_subjects.map(s => this.subjects.find(e => +e.id === +s)),
          classes: this.selectedAggregate.classes.map(cid => this.classes.find(c => +c.id === +cid)),
          exclude_subjects: this.selectedAggregate.exclude_subjects.map(s => this.subjects.find(e => +e.id === +s)),
          best_core_count: +this.selectedAggregate.best_core_count,
          best_elective_count: +this.selectedAggregate.best_elective_count,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.aggregate.__typename
      } else {
        this.resetAggregateForm()
      }
    },
    closeAggregateModal() {
      // this.removedClassIds = []
      this.aggregateModalOpened = false
      this.$emit('modalClosed')
    },
    updateAggregate() {
      this.error = {}
      if (!this.aggregate.id) {
        return
      }
      this.aggregateLoading = true
      const aggregate = {
        ...this.aggregate,
        core_subjects: this.aggregate.core_subjects.map(s => s.id),
        elective_subjects: this.aggregate.elective_subjects.map(s => s.id),
        classes: this.aggregate.classes.map(s => s.id),
        exclude_subjects: this.aggregate.exclude_subjects.map(s => s.id),
        best_core_count: +this.aggregate.best_core_count,
        best_elective_count: +this.aggregate.best_elective_count,
      }
      this.$apollo.mutate({
        mutation: UPDATE_AGGREGATE_M,
        variables: { input: aggregate },
      }).then(res => {
        this.showSuccess('Updated aggregate successfully')
        this.aggregateLoading = false
        this.$store.commit('studentGrading/updateAggregate', res.data.updateGrading)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update aggregate')
        this.aggregateLoading = false
      })
    },
    createAggregate() {
      this.error = {}
      this.aggregateLoading = true
      const aggregate = {
        ...this.aggregate,
        core_subjects: this.aggregate.core_subjects.map(s => s.id),
        elective_subjects: this.aggregate.elective_subjects.map(s => s.id),
        classes: this.aggregate.classes.map(s => s.id),
        exclude_subjects: this.aggregate.exclude_subjects.map(s => s.id),
        best_core_count: +this.aggregate.best_core_count,
        best_elective_count: +this.aggregate.best_elective_count,
      }
      this.$apollo.mutate({
        mutation: CREATE_AGGREGATE_M,
        variables: { input: aggregate },
      }).then(res => {
        this.showSuccess('Created aggregate successfully')
        this.aggregateLoading = false
        this.$store.commit('studentGrading/addAggregate', res.data.createGrading)
        this.resetAggregateForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to create aggregate')
        this.aggregateLoading = false
      })
    },
    deleteAggregate() {
      if (!this.selectedAggregateId) {
        this.showInfo('Please select a aggregate')
        return
      }
      this.deletingAggregate = true
      this.$apollo.mutate({
        mutation: DELETE_AGGREGATE_M, variables: { id: this.selectedAggregateId },
      }).then(() => {
        this.deletingAggregate = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('studentGrading/removeAggregate', this.selectedAggregateId)
        this.selectedAggregateId = null
        this.resetAggregateForm()
      }).catch(() => {
        this.deletingAggregate = false
      })
    },

  },
}
</script>
