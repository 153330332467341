<template>
  <div class="academic-years">
    <lenon-list-page
      title="School Grading Systems"
      :columns="columns"
      :rows="gradingSystemGrades"
      :table-loading="tableLoading"
      sort-column="label"
      :per-page="10"
    >
      <template
        v-if="selectedGradingSystem"
        slot="table-header"
      >
        <div class="col-12" style="margin-bottom: 7px">
          <div class="text-gray">
            Students in classes below will be graded with <strong>{{ selectedGradingSystem.name }}</strong>
          </div>
        </div>
        <div class="col-12 d-flex flex-wrap">
          <div
            v-for="(schoolClass,index) in selectedGradingSystem.classes"
            :key="index"
            style="padding-right: 7px;padding-bottom: 7px"
          >
            <b-badge variant="light-success">
              {{ schoolClass.name }}
            </b-badge>
          </div>
        </div>
      </template>
      <template slot="left-header-actions">
        <lenon-select
          v-model="selectedGradingSystemId"
          :options="gradingSystems"
          label-name="name"
          value-name="id"
          placeholder="Select Grading System"
        />
      </template>
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2 desktop-only">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-warning"
            class="mr-2"
            icon="SettingsIcon"
            label="Subjects"
            tool-tip-text="Manage Subjects (CRUD)"
            @onClick="showSubjectModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-primary"
            class="mr-2"
            icon="SettingsIcon"
            label="Grades"
            tool-tip-text="Manage Grades (CRUD)"
            @onClick="showGradeModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-primary"
            icon="SettingsIcon"
            label="Aggregate"
            tool-tip-text="Manage Aggregate Settings (CRUD)"
            class=""
            @onClick="showAggregateModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="SettingsIcon"
            label="Grading Systems"
            tool-tip-text="Manage Grading Systems (CRUD)"
            class="ml-2"
            @onClick="showGradingSystemSetupModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start mb-2"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Subjects"
              @click="showSubjectModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Grades"
              @click="showGradeModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Aggregate"
              @click="showAggregateModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Grading Systems"
              @click="showGradingSystemSetupModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
    </lenon-list-page>

    <aggregate-setting
      :modal-opened="aggregateModalOpened"
      @modalClosed="closeModal('aggregate')"
    />
    <grade-setup
      :modal-opened="gradeModalOpened"
      @modalClosed="closeModal('grade')"
    />
    <school-subject
      :modal-opened="subjectModalOpened"
      @modalClosed="closeModal('subject')"
    />
    <grading-system-setup
      :modal-opened="gradingSystemSetupModalOpened"
      @modalClosed="closeModal('gradingSystemSetup')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { GRADES_GRADING_SYSTEM_SUBJECTS_AGGREGATES_Q } from '@/graphql/queries'
import { BBadge } from 'bootstrap-vue'
import GradeSetup from '@/views/admin/academics/student-grading/GradeSetup.vue'
import GradingSystemSetup from '@/views/admin/academics/student-grading/GradingSystemSetup.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import SchoolSubject from '@/views/admin/academics/student-grading/Subject.vue'
import AggregateSetting from '@/views/admin/academics/student-grading/AggregateSetting.vue'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDropdown from '@/lenon/components/LenonDropdown'

export default {
  name: 'StudentGrading',
  components: {
    LenonDropdown,
    LenonDropdownItem,
    LenonListPage,
    AggregateSetting,
    SchoolSubject,
    LenonSelect,
    GradingSystemSetup,
    GradeSetup,
    LenonButton,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      selectedGradingSystemId: null,
      gradeModalOpened: false,
      aggregateModalOpened: false,
      subjectModalOpened: false,
      gradingSystemSetupModalOpened: false,
      tableLoading: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'label',
          label: 'Grade',
          sortable: true,
        },
        {
          key: 'min_score',
          label: 'Min Score',
        },
        {
          key: 'max_score',
          label: 'Max Score',
        },
        {
          key: 'remark',
          label: 'Remark',
        },
      ],
    }
  },
  computed: {
    gradingSystems() {
      return this.$store.getters['studentGrading/gradingSystems']
    },
    gradingSystemGrades() {
      return this.$store.getters['studentGrading/gradingSystemGrades']
    },
    selectedGradingSystem() {
      const gs = this.gradingSystems.find(ter => ter.id === this.selectedGradingSystemId)
      this.$store.commit('studentGrading/setGradingSystemGrades', gs ? gs.grades : [])
      return gs
    },
  },
  mounted() {
    this.fetchGradesGradingSystemSubjectsAggregates()
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'gradingSystemSetup':
          this.gradingSystemSetupModalOpened = false
          break
        case 'subject':
          this.subjectModalOpened = false
          break
        case 'grade':
          this.gradeModalOpened = false
          break
        case 'aggregate':
          this.aggregateModalOpened = false
      }
    },
    showGradingSystemSetupModal() {
      this.gradingSystemSetupModalOpened = true
    },
    showSubjectModal() {
      this.subjectModalOpened = true
    },
    showGradeModal() {
      this.gradeModalOpened = true
    },
    showAggregateModal() {
      this.aggregateModalOpened = true
    },
    fetchGradesGradingSystemSubjectsAggregates() {
      this.$apollo.query({ query: GRADES_GRADING_SYSTEM_SUBJECTS_AGGREGATES_Q })
        .then(res => {
          this.$store.commit('studentGrading/setGradesGradingSystemsSubjectsAggregates', res.data)
        })
    },
  },
}
</script>
