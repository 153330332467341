<template>
  <!--    Grading System-->
  <validation-observer
    ref="gradingSystemForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="updateGradingSystemMode?'Update Grading System':'Create Grading System'"
      :show="gradingSystemModalOpened"
      extra-classes="grading-system"
      size="md"
      :show-overlay="gradingSystemLoading"
      @onClose="closeGradingSystemModal()"
    >
      <!--      <p>Grading system makes it possible for lenon to grade students appropriately,-->
      <!--        to setup, select a grading system you created, then add any number of grades and their-->
      <!--        respective min and max range. A score withing the specific range will be assigned the corresponding grade.-->
      <!--      </p>-->
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedGradingSystemId"
            placeholder="All Grading Systems"
            :options="gradingSystems"
            label-name="name"
            value-name="id"
            @input="populateGradingSystemForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteGradingSystem()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
                  variant="flat-danger"
                  icon="XIcon"
                  label=""
                  tool-tip-text="Clear Form"
                  @onClick="resetGradingSystemForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="gradingSystem.name"
        name="name"
        placeholder="Name, eg. Lower Primary Grading System"
        rules="required"
        label="Grading System Name"
      />
      <div class="mt-2">
        <lenon-multi-select
          v-model="gradingSystem.classes"
          name="classes"
          label-name="name"
          value-name="id"
          placeholder="Select Classes"
          label="Affected Classes"
          :options="filteredClasses"
          rules="required"
          @remove="handleRemoveClass"
        />
        <div v-html="missingClassText('grading systems')" />
      </div>
      <b-form-checkbox
        v-model="allClasses"
        class="my-1"
      >
        Select All Classes
      </b-form-checkbox>
      <div
        v-for="(grade,i) in gradingSystem.grades"
        :key="`term-${i}`"
        style="margin-bottom: 5px;"
      >
        <div class="mt-1">
          <strong>Grade {{ i + 1 }}</strong>
        </div>
        <b-row>
          <div class="col-md-4">
            <lenon-select
              v-model="grade.grade_id"
              :options="grades"
              label-name="label"
              value-name="id"
              placeholder="Grade"
              name="grade"
              rules="required"
              label="Grade"
            />
          </div>
          <div class="col-md-3">
            <lenon-input
              v-model="grade.min_score"
              placeholder="Min"
              name="grade"
              rules="required"
              type="number"
              :show-errors="false"
              :suffix-icon="null"
              label="Min"
            />
          </div>
          <div class="col-md-3">
            <lenon-input
              v-model="grade.max_score"
              placeholder="Max"
              name="grade"
              rules="required"
              type="number"
              :show-errors="false"
              :suffix-icon="null"
              label="Max"
            />
          </div>
          <div class="col-md-2">
            <div style="margin-top: 25px;display: flex; justify-content: space-between; align-items: center;">
              <lenon-dropdown
                icon="TrashIcon"
              >
                <b-dropdown-item
                  :disabled="gradingSystem.grades.length===1"
                  @click="removeGrade(i)"
                >
                  Yes
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item>
                  No
                </b-dropdown-item>
              </lenon-dropdown>
            </div>

          </div>
        </b-row>
      </div>
      <div class="float-right mt-2">
        <lenon-button
          variant="outline-primary"
          icon="PlusIcon"
          label=""
          tool-tip-text="Add a grade"
          class=""
          @onClick="addMoreGrades()"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeGradingSystemModal()"
          />
          <lenon-button
            :label="updateGradingSystemMode?'Update':'Create'"
            :disabled="invalid"
            :loading="gradingSystemLoading"
            loading-text="Loading..."
            @onClick="updateGradingSystemMode?updateGradingSystem():createGradingSystem()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
// eslint-disable-next-line import/extensions
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import {
  CREATE_GRADING_SYSTEM_M,
  DELETE_GRADING_SYSTEM_GRADE_M,
  DELETE_GRADING_SYSTEM_M, UPDATE_GRADING_SYSTEM_M,
} from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import { missingClassText } from '@/libs/dnd'

export default {
  name: 'GradingSystemSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonInput,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      missingClassText,
      removedClassIds: [],
      allClasses: false,
      gradingSystemModalOpened: false,
      updateGradingSystemMode: false,
      gradingSystemLoading: false,
      selectedGradingSystemId: null,
      gradingSystem: {
        id: null,
        name: null,
        classes: [],
        grades: [{
          id: null,
          grade_id: null,
          min_score: null,
          max_score: null,
        }],
      },
    }
  },
  computed: {
    grades() {
      return this.$store.getters['studentGrading/grades']
    },
    gradingSystems() {
      return this.$store.getters['studentGrading/gradingSystems']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.gradingSystems.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c.id)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
    selectedGradingSystem() {
      return this.gradingSystems.find(gs => +gs.id === +this.selectedGradingSystemId)
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.gradingSystem.classes = this.filteredClasses
      } else {
        this.gradingSystem.classes = []
      }
    },
    modalOpened(opened) {
      this.gradingSystemModalOpened = opened
    },
  },
  mounted() {
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    closeGradingSystemModal() {
      // this.removedClassIds = []
      this.gradingSystemModalOpened = false
      this.$emit('modalClosed')
    },
    showGradingSystemModal() {
      this.gradingSystemModalOpened = true
    },
    resetGradingSystemForm() {
      this.gradingSystem = {
        id: null,
        name: null,
        classes: [],
        grades: [{
          id: null,
          min_score: null,
          max_score: null,
        }],
      }
      this.selectedGradingSystemId = null
      this.updateGradingSystemMode = false
      this.$refs.gradingSystemForm.reset()
    },
    populateGradingSystemForm(gs) {
      if (this.selectedGradingSystem) {
        this.updateGradingSystemMode = true
          this.removedClassIds = []
          this.gradingSystem = {
            ...this
              .selectedGradingSystem,
          }
          // eslint-disable-next-line no-underscore-dangle
          delete this.gradingSystem.__typename
        } else {
          this.resetGradingSystemForm()
        }
    },
    addMoreGrades() {
      this.gradingSystem.grades.push({
        id: null,
        min_score: null,
        max_score: null,
      })
    },
    removeGrade(i) {
      const grade = this.gradingSystem.grades[i]
      if (grade.id) {
        this.gradingSystemLoading = true
        this.$apollo.mutate({
          mutation: DELETE_GRADING_SYSTEM_GRADE_M,
          variables: { id: grade.id },
        })
          .then(res => {
            this.gradingSystemLoading = false
            this.gradingSystem.grades.splice(i, 1)
            this.showSuccess('Deleted Successfully')
            this.$store.commit('studentGrading/removeGradingSystemGrade',
              {
                id: this.gradingSystem.id,
                gradingSystemGradeId: grade.id,
              })
          })
          .catch(err => {
            this.gradingSystemLoading = false
            console.log(err)
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.gradingSystem.grades.splice(i, 1)
      }
    },
    createGradingSystem() {
      this.error = {}
      this.gradingSystemLoading = true
      const gradingSystem = {
        ...this.gradingSystem,
        classes: this.gradingSystem.classes.map(c => c.id),
        grades: this.gradingSystem.grades.map(g => ({
          ...g,
          min_score: +g.min_score,
          max_score: +g.max_score,
        })),
      }
      this.$apollo.mutate({
        mutation: CREATE_GRADING_SYSTEM_M,
        variables: { grading_system: gradingSystem },
      })
        .then(res => {
          this.gradingSystemLoading = false
          this.$store.commit('studentGrading/addGradingSystem', res.data.setupGradingSystem)
          this.showSuccess('Created Successfully')
          this.resetGradingSystemForm()
        })
        .catch(err => {
          this.gradingSystemLoading = false
          logData(err)
          this.error = err
          this.showError('Something went wrong, please try again')
        })
    },
    deleteGradingSystem() {
      if (!this.selectedGradingSystemId) {
        this.showInfo('Please select a grading system')
        return
      }
      this.deletingGradingSystem = true
      this.$apollo.mutate({
        mutation: DELETE_GRADING_SYSTEM_M,
        variables: { id: this.selectedGradingSystemId },
      })
        .then(() => {
          this.deletingGradingSystem = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('studentGrading/removeGradingSystem', this.selectedGradingSystemId)
          this.selectedGradingSystemId = null
          this.resetGradingSystemForm()
        })
        .catch(() => {
          this.deletingGradingSystem = false
        })
    },
    updateGradingSystem() {
      this.error = {}
      if (!this.gradingSystem.id) {
        return
      }
      const gradingSystem = {
        ...this.gradingSystem,
        classes: this.gradingSystem.classes.map(c => c.id),
        grades: this.gradingSystem.grades.map(g => {
          // eslint-disable-next-line no-param-reassign
          delete g.label
          delete g.remark
          delete g.__typename
          return {
            ...g,
            min_score: +g.min_score,
            max_score: +g.max_score,
          }
        }),
      }
      this.gradingSystemLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_GRADING_SYSTEM_M,
        variables: { grading_system: gradingSystem },
      })
        .then(res => {
          this.showSuccess('Updated grading system')
          this.gradingSystemLoading = false
          this.$store.commit('studentGrading/updateGradingSystem', res.data.setupGradingSystem)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update grading system')
          this.gradingSystemLoading = false
        })
    },
  },
}
</script>
