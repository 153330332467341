<template>
  <!--    Grade Form-->
  <validation-observer
    ref="gradeForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateGradeMode?'Update':'Create'} Grade`"
      :show="gradeModalOpened"
      :show-overlay="deletingGrade"
      @onClose="closeGradeModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedGradeId"
            :options="grades"
            label-name="label"
            value-name="id"
            placeholder="All Grades"
            @input="populateGradeForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteGrade()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetGradeForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="grade.label"
        name="label"
        placeholder="Grade label, eg. A1,B2, etc."
        label="Grade Label"
        rules="required"
      />
      <lenon-input
        v-model="grade.value"
        name="value"
        label="Grade Value (<b>Used to calculate student aggregate</b>)"
        placeholder="Grade value, eg. 1, 2"
        rules="required"
        type="number"
      />
      <lenon-input
        v-model="grade.remark"
        name="remark"
        placeholder="Grade remark, eg. Excellent"
        rules="required"
        label="Grade Remark"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeGradeModal()"
          />
          <lenon-button
            :label="updateGradeMode?'Update':'Create'"
            :disabled="invalid"
            :loading="gradeLoading"
            loading-text="Loading..."
            @onClick="updateGradeMode?updateGrade():createGrade()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_GRADE_M,
  DELETE_GRADE_M,
  UPDATE_GRADE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'GradeSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      deletingGrade: false,
      gradeModalOpened: false,
      updateGradeMode: false,
      gradeLoading: false,
      selectedGradeId: null,
      grade: {
        id: null,
        label: null,
        value: null,
        remark: null,
      },
    }
  },
  computed: {
    grades() {
      return this.$store.getters['studentGrading/grades']
    },
    gradingSystems() {
      return this.$store.getters['studentGrading/gradingSystems']
    },
    selectedGrade() {
      return this
        .grades.find(g => +g.id === +this.selectedGradeId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.gradeModalOpened = opened
    },
  },
  methods: {
    resetGradeForm() {
      this.selectedGradeId = null
      this.updateGradeMode = false
      this.grade = {
        id: null,
        label: null,
        value: null,
        remark: null,
      }
      this.$refs.gradeForm.reset()
    },
    populateGradeForm(gr) {
      if (this.selectedGrade) {
        this.updateGradeMode = true
        this.grade = {
          ...this
            .selectedGrade,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.grade.__typename
      } else {
        this.resetGradeForm()
      }
    },
    closeGradeModal() {
      this.gradeModalOpened = false
      this.$emit('modalClosed')
    },
    updateGrade() {
      this.error = {}
      if (!this.grade.id) {
        return
      }
      this.gradeLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_GRADE_M,
        variables: { input: { ...this.grade, value: +this.grade.value } },
      }).then(res => {
        this.showSuccess('Updated grade successfully')
        this.gradeLoading = false
        this.$store.commit('studentGrading/updateGrade', res.data.updateGrade)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update Grade')
        this.gradeLoading = false
      })
    },
    createGrade() {
      this.error = {}
      this.gradeLoading = true
      this.$apollo.mutate({
        mutation: CREATE_GRADE_M,
        variables: { input: { ...this.grade, value: +this.grade.value } },
      }).then(res => {
        this.showSuccess('Created grade successfully')
        this.gradeLoading = false
        this.$store.commit('studentGrading/addGrade', res.data.createGrade)
        this.resetGradeForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to create Grade')
        this.gradeLoading = false
      })
    },
    deleteGrade() {
      if (!this.selectedGradeId) {
        this.showInfo('Please select a grade ')
        return
      }
      this.deletingGrade = true
      this.$apollo.mutate({
        mutation: DELETE_GRADE_M,
        variables: { id: this.selectedGradeId },
      }).then(() => {
        this.deletingGrade = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('studentGrading/removeGrade', this.selectedGradeId)
        this.selectedGradeId = null
        this.resetGradeForm()
      }).catch(() => {
        this.deletingGrade = false
      })
    },
  },
}
</script>
