<template>
  <!--    Subject Form-->
  <validation-observer
    ref="subjectForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateSubjectMode?'Update':'Create'} Subject`"
      :show="subjectModalOpened"
      :show-overlay="deletingSubject"
      @onClose="closeSubjectModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedSubjectId"
            placeholder="All Subjects"
            :options="subjects"
            label-name="name"
            value-name="id"
            @input="populateSubjectForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteSubject()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
                  variant="flat-danger"
                  icon="XIcon"
                  label=""
                  tool-tip-text="Clear Form"
                  @onClick="resetSubjectForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="subject.name"
        name="subject_name"
        placeholder="Enter subject name"
        rules="required"
        label="Subject Name"
      />
      <div class="mt-2">
        <lenon-select
          v-model="subject.tag"
          name="tag"
          value-name="id"
          label-name="name"
          placeholder="Select Tag"
          :options="tags"
          rules="required"
          label="Subject Tag"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeSubjectModal()"
          />
          <lenon-button
            :label="updateSubjectMode?'Update':'Create'"
            :disabled="invalid"
            :loading="subjectLoading"
            loading-text="Loading..."
            @onClick="updateSubjectMode?updateSubject():createSubject()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import { CREATE_SUBJECT_M, DELETE_SUBJECT_M, UPDATE_SUBJECT_M } from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'

export default {
  name: 'SchoolSubject',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      subjectModalOpened: false,
      updateSubjectMode: false,
      subjectLoading: false,
      deletingSubject: false,
      selectedSubjectId: null,
      subject: {
        id: null,
        name: '',
        tag: null,
      },
      tags: [
        { id: 1, name: 'Core' },
        { id: 0, name: 'Elective' },
      ],
    }
  },
  computed: {
    selectedSubject() {
      return this.subjects.find(ter => ter.id === this.selectedSubjectId)
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
  },
  watch: {
    modalOpened(opened) {
      this.subjectModalOpened = opened
    },
  },
  methods: {
    // subject
    resetSubjectForm() {
      this.subject = {
        id: null,
        name: '',
        tag: null,
      }
      this.selectedSubjectId = null
      this.updateSubjectMode = false
      this.$refs.subjectForm.reset()
    },
    populateSubjectForm(sub) {
      if (this.selectedSubject) {
        this.updateSubjectMode = true
          this.subject = {
            ...this
              .selectedSubject,
          }
          // eslint-disable-next-line no-underscore-dangle
          delete this.subject.__typename
        } else {
          this.resetSubjectForm()
        }
    },
    closeSubjectModal() {
      this.subjectModalOpened = false
      this.$emit('modalClosed')
    },
    updateSubject() {
      this.error = {}
      if (!this.subject.id) {
        return
      }
      this.subjectLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SUBJECT_M,
        variables: { input: this.subject },
      }).then(res => {
        this.showSuccess('Updated subject successfully')
        this.subjectLoading = false
        this.$store.commit('studentGrading/updateSubject', res.data.updateSubject)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update subject')
        this.subjectLoading = false
      })
    },
    createSubject() {
      this.error = {}
      this.subjectLoading = true
      this.$apollo.mutate({
        mutation: CREATE_SUBJECT_M,
        variables: { input: this.subject },
      }).then(res => {
        this.showSuccess('Created subject successfully')
        this.subjectLoading = false
        this.$store.commit('studentGrading/addSubject', res.data.createSubject)
        this.resetSubjectForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to create subject')
        this.subjectLoading = false
      })
    },
    deleteSubject() {
      if (!this.selectedSubjectId) {
        this.showInfo('Please select a subject')
        return
      }
      this.deletingSubject = true
      this.$apollo.mutate({
        mutation: DELETE_SUBJECT_M, variables: { id: this.selectedSubjectId },
      }).then(() => {
        this.deletingSubject = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('studentGrading/removeSubject', this.selectedSubjectId)
        this.selectedSubjectId = null
        this.resetSubjectForm()
      }).catch(() => {
        this.showError('Failed to delete subject')
        this.deletingSubject = false
      })
    },

  },
}
</script>
